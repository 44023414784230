import pathServices from "../../services/path.services"

export default {
    namespaced: true,
    state: {
        alert: {
            show: false,
            type: "info",
            message: "",
            timeout: 2500,
        },
        loader: false,
        sideBar: false,
        menu: [],
        rutas: [],
        menuHeader: [
            {
                id: 0,
                name: 'Inicio',
                icon: 'mdi-home',
                redirect_to: '/home'
            },
            {
                id: 1,
                name: 'Noticias',
                icon: 'mdi-newspaper',
                img: 'noticias-img.svg',
                desc: 'Somos un medio de comunicación formado por profesionales',
                redirect_to: '/noticias',
                order: 1
            },
            {
                id: 2,
                name: 'Mercado laboral',
                icon: 'mdi-briefcase-outline',
                img: 'mercado-laboral-img.svg',
                desc: 'Somos un medio de comunicación formado por profesionales',
                redirect_to: '/mercado-laboral',
                order: 6
            },
            {
                id: 3,
                name: 'Ventanilla de trámites',
                icon: 'mdi-file-check-outline',
                img: 'ventanilla-tramites-img.svg',
                desc: 'Somos un medio de comunicación formado por profesionales',
                redirect_to: '/tramites',
                order: 3
            },
            {
                id: 4,
                name: 'SAC',
                icon: 'mdi-comment-alert-outline',
                img: 'SAC-img.svg',
                desc: 'Somos un medio de comunicación formado por profesionales',
                redirect_to: '/sistema-atencion',
                order: 5
            },
            {
                id: 5,
                name: 'Formación',
                icon: 'mdi-school-outline',
                img: 'formacion-img.svg',
                desc: 'Somos un medio de comunicación formado por profesionales',
                redirect_to: '/formacion',
                order: 2
            },
            {
                id: 6,
                name: 'Recreación',
                icon: 'mdi-ferris-wheel',
                img: 'recreacion-img.svg',
                desc: 'Somos un medio de comunicación formado por profesionales',
                redirect_to: '/recreacion',
                order: 4
            }
        ],
        menuCard: [
            {
                id: 1,
                name: 'Diario El Salvador\n',
                icon: 'mdi-newspaper',
                img: 'dashboard_s1.jpg',
                desc: 'Somos un medio de comunicación formado por profesionales comprometidos con llevar la verdad a nuestras audiencias',
                redirect_to: '/noticias',
                order: 1
            },
            {
                id: 2,
                name: 'Formación',
                icon: 'mdi-school-outline',
                img: 'dashboard_s2.jpg',
                desc: 'Desarrolla tu carrera profesional en la ESIT y conviértete en un líder de la transformación digital',
                redirect_to: '/formacion',
                order: 2
            },
            {
                id: 3,
                name: 'Ventanilla de trámites',
                icon: 'mdi-file-check-outline',
                img: 'dashboard_s3.jpg',
                desc: 'La Ventanilla Única del Gobierno de El Salvador. Conoce los servicios de gobierno y realiza tus trámites en un solo lugar',
                redirect_to: '/tramites',
                order: 3
            },
            {
                id: 4,
                name: 'Recreación',
                icon: 'mdi-ferris-wheel',
                img: 'recreacion-img.svg',
                desc: 'Información turística oficial sobre El Salvador, una guía completa para conocer el país más pequeño de Centroamérica',
                redirect_to: '/recreacion',
                order: 4
            },
            {
                id: 5,
                name: 'SAC',
                icon: 'mdi-comment-alert-outline',
                img: 'dashboard_s5.jpg',
                desc: 'Crea y da seguimiento a tus comentarios o denuncias ciudadanas',
                redirect_to: '/sistema-atencion',
                order: 5
            },
            {
                id: 6,
                name: 'Mercado laboral',
                icon: 'mdi-briefcase-outline',
                img: 'mercado-laboral-img.svg',
                desc: 'Se parte de la base de datos de profesionales para la bolsa de trabajo del Gobierno de El Salvador',
                redirect_to: '/mercado-laboral',
                order: 6
            },




        ],

    },
    getters: {
        getAlert: (state) => {
            return state.alert;
        },
    },
    mutations: {
        setAlert: (state, payload) => {
            state.alert = payload;
        },
        setLoader: (state, payload) => {
            state.loader = payload;
        },
        setSideBar: (state, payload) => {
            state.sideBar = payload;
        },
        setMenu: (state, menu) => {
            state.menu = menu;
        },
        setRutas: (state, rutas) => {
            state.rutas = rutas;
        },
    },
    actions: {
        getMenu: async ({commit}) => {
            const response = await pathServices.getAuthorizedPaths() //editado-w
            //const response = {data:[]}
            const menu = getNavItem(response?.data)
            commit("setMenu", menu);
            commit("setRutas", response.data);

        },
        clearMenu: ({commit}) => {
            commit("setMenu", []);
            commit("setRutas", []);
        }
    },
};

const getNavItem = (items) => {
    return items.filter((item) => {
        if (!item.mostrar) return false
        if (item.childrens?.length > 0) item.childrens = getNavItem(item.childrens)
        return true
    })
}
