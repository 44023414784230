<template>
    <div
        :class="[
        `text-${heading}`,
        `mb-${margin}`,
        `font-weight-${weight}`,
        `d-flex ${flex}`,
        `text-${transform}`,
        `text-${alignment}`
        ]"
        class="text-center"
        :style="color"
        v-text="text"
    >
    </div>
</template>
<script>

export default {
    props:{
        heading: String,
        text: String,
        size: String,
        weight: String,
        spacing: String,
        margin: {
            typeof: String,
            default: 0,
        },
        flex: String,
        textColor: String,
        transform: {
            type: String,
            default: ''
        },
        alignment: {
            type: String,
            default: 'center'
        },
    },
    data:() =>({
    }),
    computed:{
        color(){
            return {'color' : this.textColor }
        },        
    }
}
</script>
