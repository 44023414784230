import Vue from "vue";
import VueRouter from "vue-router";
import { mercadoLaboralRoutes } from "../views/mercado-laboral/router";
import { homeRoutes } from "../views/Home/router";
import { recreacionRouter } from "../views/recreacion/recreacionRouter";
import { permit } from "../plugins/utils";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import(/* webpackChunkName: "layout" */ "../views/layout"),
    children: [
      {
        path: "/",
        name: "dashboard",
        component: () =>
          import(/* webpackChunkName: "profiles" */ "../views/dashboard"),
      },
      {
        path: "profile",
        name: "profile",
        component: () =>
          import(/* webpackChunkName: "profiles" */ "../views/profile"),
      },
      {
        path: "/security",
        name: "security",
        component: () =>
          import(/* webpackChunkName: "qr" */ "../views/auth/security"),
      },
      {
        path: "/sistema-atencion",
        name: "SAC",
        component: () =>
          import(
            /* webpackChunkName: "resetPassword" */ "../views/SAC/index.vue"
          ),
      },
      {
        path: "/noticias",
        name: "noticias",
        component: () =>
          import(
            /* webpackChunkName: "noticias" */ "../views/Noticias/NoticiasInovacion.vue"
          ),
      },
      {
        path: "/noticia_princial/:id",
        name: "noticia_princial",
        component: () =>
          import(
            /* webpackChunkName: "noticias" */ "../views/Noticias/NoticiaPrincipal.vue"
          ),
      },
      {
        path: "/tramites",
        name: "tramites",
        component: () =>
          import(
            /* webpackChunkName: "tramites" */ "../views/VentanillaTramites.vue"
          ),
      },
      {
        path: "/formacion",
        name: "formacion",
        component: () =>
          import(
            /* webpackChunkName: "formacion" */ "../views/formacion/Formacion.vue"
          ),
      },
      ...mercadoLaboralRoutes,
      ...homeRoutes,
      ...recreacionRouter,
    ],
  },
  // {
  //     path:"/tramites",
  //     name:"tramites",
  //     component: () => import(/* webpackChunkName: "tramites" */ "../views/VentanillaTramites.vue"),
  // },
  // {
  //     path:"/formacion",
  //     name:"formacion",
  //     component: () => import(/* webpackChunkName: "formacion" */ "../views/formacion/Formacion.vue"),
  // },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/login"),
  },
  {
    path: "/recuperar-password",
    name: "recuperar-password",
    component: () =>
      import(
        /* webpackChunkName: "recuperarPassword" */ "../views/auth/recoverPassword"
      ),
  },
  {
    path: "/reset-password/:id",
    name: "reset-password",
    component: () =>
      import(
        /* webpackChunkName: "resetPassword" */ "../views/auth/resetPassword"
      ),
  },
  {
    path: "/verify-mail/:token",
    name: "verifyMail",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/verifyMail"),
  },
  {
    path: "/forbidden",
    name: "Forbidden",
    component: () =>
      import(/* webpackChunkName: "forbidden" */ "../views/forbidden"),
  },
  {
    path: "/autenticacionqr",
    name: "2fa",
    component: () => import(/* webpackChunkName: "qr" */ "../views/auth/2fa"),
  },
  {
    path: "/registro",
    name: "registro",
    component: () =>
      import(/* webpackChunkName: "registro" */ "../views/registro/registro"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  /*if (to.fullPath === "/") {
    const token = localStorage.getItem("token");
    // if (to.fullPath!=='/login' && !to.fullPath.includes('code')){
    //     if (!token)router.push('/login')
    if (! (permit.some((row) => row.toLowerCase() === to.name.toLowerCase()))) {
        if (!token)router.push('/login') //editado-w
    }
    if (to.fullPath === "/") {
    if (!token) {
        next("/login")
        //next(); //editado-w
        return;
    }
  }*/
  if (to.fullPath === "/") {
    const token = localStorage.getItem("token");
    if (!token) {
      next("/login");
      //next();
      return;
    }
  }
  if (await Vue.prototype.canNext(to)) {
    next();
  } else {
    // next("/forbidden");
    next();
  }
});

export default router;
