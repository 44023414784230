<template>
  <v-row justify="center">
    <v-dialog
        v-model="sideBar"
        fullscreen
        hide-overlay
        persistent
        transition="slide-x-transition"
        :scrollable="false"

    >
      <v-card v-if="$vuetify.breakpoint.width > 500" >
        <v-toolbar elevation="0" height="100" class="px-10">
          <v-row class="justify-end align-center">
            <v-img
                src="../assets/Icon-header-color.svg"
                max-width="150"
                class="ma-8"
            ></v-img>
            <v-spacer></v-spacer>
            <v-btn
                icon
                dark
                @click="setSideBar(!sideBar)"
            >
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-toolbar>
        <v-list class="mt-10 px-10" nav >

          <v-list-item v-for="menu in menuHeader" :key="menu.id" link @click="redirectTo(menu)">
            <v-list-item-avatar>
              <v-icon>
                {{ menu.icon }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title tag="p" class="ma-0 ">{{ menu.name }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-avatar>
              <v-icon>
                mdi-chevron-right
              </v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>
        <v-row class="justify-center mt-5">
          <v-btn color="textCyan" class="rounded-xl white--text" @click="closeSession">Cerrar sesión</v-btn>
        </v-row>

      </v-card>
      <v-card v-else class="side__backgdound ">

        <v-toolbar elevation="0" height="100" style="background: transparent">
          <v-row class="justify-start align-center">
            <v-btn
                icon
                @click="closeSideBar"
            >
              <v-icon color="white">mdi-menu</v-icon>
            </v-btn>
          </v-row>
        </v-toolbar>

        <v-list class="mt-10 px-10" nav style="background: transparent" >

          <v-list-item v-for="menu in menuHeader" :key="menu.id" link @click="redirectTo(menu)" class="white--text">
            <v-list-item-avatar>
              <v-icon color="white">
                {{ menu.icon }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title tag="p" class="ma-0 ">{{ menu.name }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-avatar>
              <v-icon color="white">
                mdi-chevron-right
              </v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>
        <v-row class="justify-center mt-5">
          <v-btn color="textCyan" class="rounded-xl white--text" @click="closeSession">Cerrar sesión</v-btn>
        </v-row>


          <div class="d-flex justify-center" style="margin-top: 100%">
            <v-img
                src="../assets/Icon-header.svg"
                max-width="150"
                class="ma-8"
            ></v-img>
          </div>


      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import {mapMutations, mapState} from "vuex";

export default {
  props: {
    menu: {
      type: Array,
      default: []
    }
  },
  methods: {
    ...mapMutations('utils', ['setSideBar']),
    redirectTo(menu) {
      localStorage.setItem('id', menu.id)
      const pathNow = this.$route.path
      setTimeout(() => {
        if (menu.redirect_to !== pathNow) {
          this.$router.push({path: menu.redirect_to})
        }
        document.documentElement.classList.remove("overflow-y-hidden");
        this.setSideBar(false)
      }, 200)
    },
    closeSideBar(){
      document.documentElement.classList.remove("overflow-y-hidden");
      this.setSideBar(!this.sideBar)
    },
    closeSession(){
      localStorage.removeItem('token')
      this.$router.push({path: 'login'})
    }
  },
  computed: {
    ...mapState('utils', ['sideBar', 'menuHeader'])
  }
}
</script>
<style scoped lang="scss">
.side__backgdound {
  background-image: url('../assets/Bg-menu-lateral.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
}
</style>
