<template>

  <v-container fluid class="pa-0" style="background-color: white">
    <v-app-bar elevation="0" class="bar-style-image " :class="isBorderHeader ? 'bar-app--style' : ''"
               :height="heigthHeader">
      <template #default>
        <v-app-bar-nav-icon @click.stop="openSideBar" v-if="!isShow" class="white--text"></v-app-bar-nav-icon>
        <v-row class="align-center mt-5" style="width: 100%" v-if="isShow">
          <v-img
              :src="getIcon"
              max-width="120"
              class="ma-8"
          ></v-img>
          <v-spacer></v-spacer>
          <v-col class="d-flex justify-end" cols="2" v-if="isMenuBtn &&  $vuetify.breakpoint.width < 1200">
            <v-btn color="transparent" class="white--text" elevation="10" @click.stop="openSideBar">Menú
            </v-btn>
          </v-col>
          <v-tabs v-model="tabsHeader" class="tabs-style" fixed-tabs background-color="transparent"
                  :slider-color="tabColor"
                  v-else
          >
            <v-tab :class="`${tabColor}--text`" v-for="tab in tabs" :key="tab.id"
                   @click="redirectTo(tab.redirect_to, tab.id)"
                   style="min-width: 0 !important; padding: 0; margin: 0"
                   :show-arrows="false">
              <v-icon :color="tabColor" class="pr-1">{{ tab.icon }}</v-icon>
              <span class="text-caption ">{{ tab.name }}</span>
            </v-tab>
            <div  class="v-tab white--text" @click="closeSession">
              Cerrar sesión
            </div>
          </v-tabs>


        </v-row>

        <div v-if="isCarousel" style="max-width: 100%">
          <slot name="carousel"></slot>
        </div>
      </template>
      <template v-slot:img="{ props }">
        <v-img
            :src="getImage"
            :height="heigthHeader"
            @error="ImagenError()"
        >

          <template #default>
            <div class="header-img--title-style" :style="{top: isCarousel ? topTileCarousel : topTitle }">
              <slot name="title"></slot>
            </div>
            <div class="header-additional--style">
              <slot name="additional"></slot>
            </div>
          </template>
        </v-img>
      </template>

    </v-app-bar>
    <slot name="body-content">
    </slot>
    <v-footer v-if="isFooter" class="pa-0 mt-5" elevation="0" color="white">
      <v-card :img="require('../assets/BG-footer.svg')" width="100%"
              style=" border-radius: 80px 80px 0px 0px !important;">
        <v-card-text>
          <v-row :class="$vuetify.breakpoint.mdAndUp ? 'mt-3' : ''">
            <v-col cols="4" v-if="$vuetify.breakpoint.mdAndUp">
            </v-col>
            <v-col class="d-flex justify-center align-center my-0" cols="12" md="4">

              <v-img src="../assets/Logo-header-sv.svg" width="100%"></v-img>
            </v-col>
            <v-col class="d-flex justify-center align-center" md="4" style="gap: 15px">
              <v-btn icon v-for="btn in btnSocialRed" :key="btn.id" link  :href="btn.redirect_to">
                <v-icon color="white" large>{{ btn.icon }}</v-icon>
              </v-btn>

            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-container>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "AppHeader",
  data() {
    return {
      tabsHeader: '',
      btnSocialRed: [
        {
          id: 1,
          icon: 'mdi-facebook',
          redirect_to: 'https://www.facebook.com/GobiernoSV/'
        },
        {
          id: 2,
          icon: 'mdi-twitter',
          redirect_to: 'https://twitter.com/Gobierno_SV'
        },
        {
          id: 3,
          icon: 'mdi-youtube',
          redirect_to: 'https://www.youtube.com/@GobiernoSV/videos'
        }
      ]
    }
  },
  props: {
    image: {
      type: String,
      default: ''
    },
    changeIcon: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'Icon-header.svg'
    },
    isFooter: {
      type: Boolean,
      default: true
    },
    tabColor: {
      type: String,
      default: 'white'
    },
    isBorderHeader: {
      type: Boolean,
      default: true
    },
    isCarousel: {
      type: Boolean,
      default: false
    },
    isMenuBtn: {
      type: Boolean,
      default: true
    },
    xs: {
      type: Number,
      default: 0
    },
    sm: {
      type: Number,
      default: 0
    },
    md: {
      type: Number,
      default: 0
    },
    lg: {
      type: Number,
      default: 0
    },
    xl: {
      type: Number,
      default: 0
    }

  },
  methods: {
    ...mapMutations('utils', {
      setSideBar: 'setSideBar'
    }),
    cerrarSession() {
      localStorage.clear()
      this.$router.push('/login').catch(e => {
      })
    },
    openSideBar(){
      document.documentElement.classList.add("overflow-y-hidden");
      this.setSideBar(!this.sideBar)
    },
    redirectTo(path, id) {
      localStorage.setItem('id', id)
      const pathNow = this.$route.path
      if (path !== pathNow) {
        this.$router.push({path: path})
      }
    },
    changeTab() {
      this.tabsHeader = Number(localStorage.getItem('id'))
    },
    closeSession(){
      localStorage.removeItem('token')
      this.$router.push({path: 'login'})
    },
    ImagenError(){
      this.$emit('errorImagen');
    },
  },
  computed: {
    ...mapState(['userInfo']),
    ...mapState('utils', {
      sideBar: 'sideBar',
      tabs: 'menuHeader'
    }),
    heightImg() {
      if (this.$vuetify.breakpoint.xs && this.isCarousel) return '800'
      else if (this.$vuetify.breakpoint.sm && this.isCarousel) return '800'
      else if (this.$vuetify.breakpoint.md && this.isCarousel) return '550'
      else if (this.$vuetify.breakpoint.lg && this.isCarousel) return '850'
      else if (this.$vuetify.breakpoint.xl && this.isCarousel) return '1100'
      else if (this.$vuetify.breakpoint.xs) return '200'
      else if (this.$vuetify.breakpoint.sm) return '300'
      else if (this.$vuetify.breakpoint.md) return '400'
      else if (this.$vuetify.breakpoint.lg) return '600'
      else return '800'
    },
    heigthHeader() {

      if (this.$vuetify.breakpoint.xs && this.isCarousel) return '680'
      else if (this.$vuetify.breakpoint.sm && this.isCarousel) return '650'
      else if (this.$vuetify.breakpoint.md && this.isCarousel) return '550'
      else if (this.$vuetify.breakpoint.lg && this.isCarousel) return '850'
      else if (this.$vuetify.breakpoint.xl && this.isCarousel) return '1100'
      else if (this.$vuetify.breakpoint.xs) return this.xs || '200'
      else if (this.$vuetify.breakpoint.sm) return this.sm || '300'
      else if (this.$vuetify.breakpoint.md) return this.md || '400'
      else if (this.$vuetify.breakpoint.lg) return this.lg || '600'
      else return '800'

    },
    topTileCarousel() {
      return this.$vuetify.breakpoint.smAndDown ? '10%' : '30%'
    },
    topTitle() {
      return this.$vuetify.breakpoint.smAndDown ? '30%' :
          this.$vuetify.breakpoint.md ? '45%' :
              this.$vuetify.breakpoint.lg ? '170px' :
                  this.$vuetify.breakpoint.xl ? '255px' : ''
    },
    getImage() {
      if(!this.image?.includes('http') && !!this.image){
        return require(`../assets/${this.image}`)
      }else{
        return this.image
      }
    },
    getIcon() {
      return this.isShow && !this.changeIcon ? require('../assets/Icon-header.svg') : require(`../assets/${this.icon}`)
    },
    // getIconMovil() {
    //   return require(`../assets/${this.icon}`)
    // },
    isShow() {
      return this.$vuetify.breakpoint.width > 500
    }
  },
  // watch:{
  //   tabsHeader:{
  //     handler(newVal){
  //       console.log(newVal)
  //       this.tabsHeader = newVal
  //     },
  //     immediate: true
  //   }
  // },
  beforeMount() {
    const path =  this.tabs.filter((e)=> e.redirect_to === this.$route.fullPath)
    localStorage.setItem('id', path[0].id)
    this.tabsHeader = Number(localStorage.getItem('id'))
  },
  created() {
    this.tabsHeader = Number(localStorage.getItem('id'))
  }
}
</script>

<style lang="scss" scoped>
.gradiant-text {
  background: #077BD9;
  background: linear-gradient(to right, #077BD9 0%, #46E093 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

::v-deep .v-toolbar__content {
  align-items: start !important;
  flex-wrap: wrap !important;

  //border-bottom-right-radius: 172% 36% !important;
  //border-bottom-left-radius: 172% 36% !important;
}

::v-deep .v-toolbar__extension {
  align-items: flex-start !important;

}

.bar-style-image {
  background-color: white !important;
}


.bar-app--style ::v-deep .v-toolbar__image {

  border-bottom-right-radius: 172% 36%;
  border-bottom-left-radius: 172% 36%;
}


::v-deep .v-toolbar__image {

  .header-img--title-style {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .header-additional--style {
    display: flex;
    justify-content: center;
  }
}

.tabs-style {
  background-color: transparent !important;
  width: auto !important;
}
</style>
