import {http_client} from "@/plugins/http_client";

const ctl_nacionalidad = async (params) => await http_client('/api/v1/nacionalidad', params, 'get');
const ctl_sexo = async (params) => await http_client('/api/v1/sexo', params, 'get');
const ctl_tipo_documento = async (params) => await http_client('/api/v1/tipo-documento', params, 'get');
const ctl_proposito_uso = async (params) => await http_client('/api/v1/proposito-uso', params, 'get');

const postRegistro = async (body) => await http_client('/api/v1/registro', body, 'post');

export default {
    ctl_nacionalidad,
    ctl_sexo,
    ctl_tipo_documento,
    ctl_proposito_uso,
    postRegistro
}
