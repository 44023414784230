<template>
  <div class="map-container">
    <l-map :zoom="zoom" :center="waypoints" v-if="readyMap">
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-marker :lat-lng="waypoints"></l-marker>
    </l-map>
  </div>
</template>
<script>
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";

export default ({
  props: {
    waypoints: Array,
    readyMap: Boolean,
  },
  components: {
    LMap, 
    LTileLayer,
    LMarker
  },
  data(){
    return{
      zoom: 20,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }
  },
})
</script>


<style lang="scss" scoped>
@import "../../node_modules/leaflet/dist/leaflet.css";

html,
body,
.map-container {
  width: 100%;
  height: 60vh;
  margin-top: 10px;
}
.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  border-radius: 50px;
}

@media screen and (max-width: 600px) {
  .map-container {
    height : 30vh
  }
}
</style>
