import Vue from "vue";
import auth from './auth.services'
import path from "./path.services";
import tramites from "./tramites.services";
import registro from './registro.services';
import MercadoLaboral from "@/views/mercado-laboral/services/mercado-laboral.services";
import Noticias from '@/views/Home/services/home.services'
import formacion from './formacion.services';
import Recreacion from "@/views/recreacion/services/recreacion.services";

Vue.prototype.services = {
  auth,
  path,
  registro,
  MercadoLaboral,
  Noticias,
  tramites,
  formacion,
  Recreacion
}
