import {http_client} from "../../../plugins/http_client";


const getLugar = async (id, params = {}) => await http_client(`/api/v1/lugares/${id}`, params)

const getEventos = async (params) => {
  const response = await http_client(`/api/v1/eventos`,  params );
  return response;
};

const getEventobyId = async (id) => {
  const response = await http_client(`/api/v1/eventos/${id}`,{},'GET');
  return response;
};

const getEmergencia = async (params) => {
  const response = await http_client(`/api/v1/instituciones-emergencia`,  params );
  return response;
};
const getCategoria = async (params) => {
  const response = await http_client(`/api/v1/categorias`,  params );
  return response;
};
const getImagen = async (params = {}) => {
  const response = await http_client(`/api/v1/recreacion/imagen`, params);
  return response;
};
const getLugares = async (id=0,params = {}) => {
  const response = await http_client(`/api/v1/categorias/${id}/lugares`, params );
  return response;
};
const getEventoInfo = async (id=0,params = {}) => {
  const response = await http_client(`/api/v1/eventos/${id}`, params );
  return response;
};

export default {
  getEventos,
  getEventobyId,
  getImagen,
  getEmergencia,
  getCategoria,
  getLugares,
  getEventoInfo,
  getLugar
};
