import { http_client } from "../plugins/http_client";

const url = "https://formacioncontinua.esit.gob.sv/api/cursos";

const getCursos = async (queryParams = {}) =>
  await http_client(url, queryParams, "get");

const listar = async (filtros) =>
  await http_client(`/api/v1/publicaciones`, filtros, "get");

const obtenerPublicaciones = (filtros) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await http_client(
        `/api/v1/publicaciones`,
        filtros,
        "get"
      );

      if (!Array.isArray(data)) {
        console.error(data);
        reject(new Error("Respuesta inválida."));
      }

      const publicaciones = data.map((data) => {
        const { id, nombre, PublicacionImagens } = data;
        let urlPortada = null;
        let imagenes = [];
        if (Array.isArray(PublicacionImagens)) {
          imagenes = PublicacionImagens.filter(({ activo }) => activo).map(
            (data) => {
              const { id, url: filename, isPortada } = data;
              if (isPortada) {
                urlPortada = `${process.env.VUE_APP_API_URL}/publicaciones/${filename}`;
              }
              return {
                id,
                url: `${process.env.VUE_APP_API_URL}/publicaciones/${filename}`,
              };
            }
          );
        }

        return {
          id,
          nombre,
          url: urlPortada,
          imagenes,
        };
      });

      resolve(publicaciones);
    } catch (error) {
      reject(error);
    }
  });
};

export default {
  getCursos,
  listar,
  obtenerPublicaciones,
};
