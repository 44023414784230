<template>
  <v-app-bar elevation="0" class="bar-style">
    <template v-slot:img="{ props }">
      <v-img
          :src="getImage"
          class="header-align haader-img--border"
          @error="ImagenError"
      >
        <template #default>
          <div class="header-img--title-style">
            <slot name="title"></slot>
          </div>
        </template>
      </v-img>
    </template>
    <v-row class="align-center">
      <v-col>
        <v-img
            src="../assets/Icon-header.svg"
            max-width="150"
            class="ma-8"
        ></v-img>
      </v-col>
      <v-spacer></v-spacer>
      <v-col>
        <v-tabs class="tabs-style" fixed-tabs background-color="transparent" slider-color="white">
          <v-tab class="white--text" v-for="tab in tabs" :key="tab.id" @click="redirectTo(tab.redirect_to)">
            <v-icon color="white" class="pr-1">{{ tab.icon }}</v-icon>
            <span>{{ tab.name }}</span>
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
  </v-app-bar>
</template>
<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "AppHeader",
  data() {
    return {
      tabs: [
        {
          id: 1,
          name: 'Inicio',
          icon: 'mdi-home',
          redirect_to: '/home'
        },
        {
          id: 2,
          name: 'Noticias',
          icon: 'mdi-newspaper',
          redirect_to: '/noticias'
        },
        {
          id: 3,
          name: 'Mercado laboral',
          icon: 'mdi-briefcase-outline',
          redirect_to: '/mercado-laboral'
        },
        {
          id: 4,
          name: 'Ventanilla de trámites',
          icon: 'mdi-file-check-outline',
          redirect_to: '/tramites'
        },
        {
          id: 5,
          name: 'SAC',
          icon: 'mdi-comment-alert-outline',
          redirect_to: '/sistema-atencion'
        },
        {
          id: 6,
          name: 'Formación',
          icon: 'mdi-school-outline',
          redirect_to: '/formacion'
        },
        {
          id: 7,
          name: 'Recreación',
          icon: 'mdi-ferris-wheel',
          redirect_to: '/recreacion'
        },
      ],
    }
  },
  props: {
    image: {
      type: String,
      default: ''
    },
  },
  methods: {
    ...mapMutations('utils', ['setSibeBar']),
    cerrarSession() {
      localStorage.clear()
      this.$router.push('/login').catch(e => {
      })
    },
    ImagenError(){
      this.$emit('errorImagen');
    },
    redirectTo(path) {
      this.$router.push({path: path})
    }
  },
  computed: {
    ...mapState(['userInfo']),
    ...mapState('utils', {
      sideBar: 'sideBar'
    }),
    getImage() {
      if(!this.image.includes('http')  && !!this.image){
        return require(`../assets/${this.image}`)
      }else{
        return this.image
      }
    }
  },
  created() {
    if (this.image === "") {

    }
  }
}
</script>

<style lang="scss" scoped>
.gradiant-text {
  background: #077BD9;
  background: linear-gradient(to right, #077BD9 0%, #46E093 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

::v-deep .v-toolbar__content {
  align-items: start !important;
  border-bottom-right-radius: 172% 36% !important;
  border-bottom-left-radius: 172% 36% !important;
}

.bar-style {
  background-color: white !important;
  height: 37vw !important;
}

::v-deep .v-toolbar__image {

  //border-radius: 0px 0px 155px 155px;
  border-bottom-right-radius: 172% 36%;
  border-bottom-left-radius: 172% 36%;

  .header-img--title-style {
    position: relative;
    top: 40%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }


}

.tabs-style {
  background-color: transparent !important;
  width: auto !important;
}


</style>
