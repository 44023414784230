
import {http_client} from "../plugins/http_client";

const url = process.env.VUE_APP_API_URL_SIMPLE;

const getTramites = async (queryParams = {}) => await http_client(`${url}tramites/habilitado`, queryParams, 'get')
const getCategorias = async (queryParams = {}) => await http_client(`${url}categorias`, queryParams, 'get')

export default {
    getTramites,
    getCategorias
}