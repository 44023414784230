export const mercadoLaboralRoutes = [
  {
    path: "/mercado-laboral",
    name: "mercado-laboral",
    component: () =>
        import(/* webpackChunkName: "perfil" */ "../mercado-laboral/index.vue"),
  },
  {
    path: "/mercado-laboral-detalles/:id",
    name: "mercado-laboral-detalles",
    component: () =>
        import(/* webpackChunkName: "perfil" */ "../mercado-laboral/components/detalleEmpleoComponent.vue"),
  },
]
