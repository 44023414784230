import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '../assets/scss/index.scss';
import es from "vuetify/es5/locale/es";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            //Se definen colores globales para el tema claro
            light : {
                blueMinsal:'#175EFB',
                blueGrayMinsal:'#6A83BE',
                blueDarkMinsal:'#2D52A8',
                bgMinsal:'#F4F7FD',
                bgWhite:"#FFFFFF",
                primary: '#175EFB',
                textCyan: "#30A4F1",
                cardBackground: "#F4F7FD",
                textGray: "#697182"
            },
            dark:{
                bgMinsal:'#2f3032',
                blueMinsal:'#175EFB',
                blueGrayMinsal:'#6A83BE',
                blueDarkMinsal:'#2D52A8',
                primary: '#175EFB'
                // primary: {
                //     base: colors.purple.base,
                // }
            }
        }
    },

    lang: {
        locales: { es },
        current: "es",
    },
});
