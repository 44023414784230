export const recreacionRouter = [

    {
      path: "/recreacion",
      name: "recreacion-index",
      component: () =>
        import(
          /* webpackChunkName: "vistaAutorizarPermisos" */ "./RecreacionIndex.vue"
        ),
    },
    {
      path: "/recreacion/detalle-evento/:id",
      name: "detalle-evento",
      component: () =>
        import(
          /* webpackChunkName: "vistaAutorizarPermisos" */ "./DetalleEvento.vue"
        ),
    },
    {
      path: "/recreacion/detalle-lugar/:id",
      name: "detalle-lugar",
      component: () =>
        import(
          /* webpackChunkName: "vistaAutorizarPermisos" */ "./DetalleLugar.vue"
          )
        },
    {
      path: "/recreacion/detalle-categoria/:id",
      name: "detalle-categoria",
      component: () =>
        import(
          /* webpackChunkName: "vistaAutorizarPermisos" */ "./DetalleCategoria.vue"
        ),
    },
  ];

