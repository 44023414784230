import {http_client} from "../../../plugins/http_client";

const url = '/api/v1/mercado-laboral'
const getEmpleos = async (params = {}) => await http_client(`${url}/website`, params)
const getEmpleo = async (id_empleo,params = {}) => await http_client(`${url}/website/${id_empleo}`, params)
const getInstituciones = async (params = {}) => await http_client(`${url}/instituciones`,params)
export default {
  getEmpleos,
  getEmpleo,
  getInstituciones
}
